// src/pages/SuccessPage.tsx
import React from 'react';

const SuccessPage: React.FC = () => {
  return (
    <div>
      <h1>Integration Unsuccessful</h1>
      <p>You have unable to connect you SOS Inventory account. Please reach out to a SEAD Representative for further help.</p>
    </div>
  );
};

export default SuccessPage;
