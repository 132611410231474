import axios from 'axios';

const API_BASE_URL = '/api/sosinventory';

export const checkTokenStatus = async (): Promise<boolean> => {
  const response = await axios.get<{ isConnected: boolean }>(`${API_BASE_URL}/status`);
  return response.data.isConnected;
};

export const connectAccount = (setIsConnected: React.Dispatch<React.SetStateAction<boolean>>) => {
  const width = 600, height = 700;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;
  const url = `${process.env.REACT_APP_BACKEND_HOST_URL}${API_BASE_URL}/connect`;

  const options = `width=${width},height=${height},top=${top},left=${left}`;
  const popup = window.open(url, 'SOSInventoryOAuth', options);

  const handleMessage = (event: MessageEvent) => {
    console.log(event.origin, process.env.REACT_APP_BACKEND_HOST_URL, event.data)
    if (event.origin === process.env.REACT_APP_BACKEND_HOST_URL && event.data === "oauth:success") {
      console.log("OAuth Success");
      if (popup) {
        popup.close();
      }
      window.removeEventListener('message', handleMessage);

      // Trigger state update
      checkTokenStatus().then(isConnected => {
        setIsConnected(isConnected);
      }).catch(error => console.error('Error checking token status:', error));
    }
  };

  window.addEventListener('message', handleMessage, false);
};

export const removeToken = async (): Promise<void> => {
  await axios.delete(`${API_BASE_URL}`);
};
